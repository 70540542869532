import React from "react";

const ListingPage = () => {
  return (
    <div class=" shadow preview_modal">
      <div class="child">
        <div
          className="comman_inner_bg"
          style={{ backgroundImage: "url(assets/img/ZPage_2.png)" }}>
          <div className="comman_head">
            <h2>Star Impoters &amp; Wholesalers - 770.908.0404</h2>
          </div>
          <div className="product_main">
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
            <a href="javascript:;" className="product_box">
              <div className="product_img">
                <img src="assets/img/product1.png" alt="" />
              </div>
              <h2>
                Show Cone <br /> 15CT/2PK <br />
                $8.49{" "}
              </h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingPage;
